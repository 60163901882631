import React from "react";

const Home = () => <div>this is home page</div>;
// export async function getServerSideProps(ctx) {
//     return {
//         redirect: {
//             destination: "/conversations",
//             permanent: false,
//         },
//     };
// }
export default Home;
